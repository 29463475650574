.three-step-process {
    padding: 2rem 1rem;
    font-family: 'Inter', sans-serif;
  }
  
  .three-step-header {
    display: flex;
    text-align: left;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto 3rem;
    
  }
  
  .three-step-header h2 {
    font-size: 2.75rem;
    font-weight: 500;
    margin: 0;
    
  }
  .three-step-button {
    background-color: #1a76c2;
    color: white;
    padding: 1rem 2rem;
    font-weight: 500;
    font-size: 1.2rem;
    border: none;
    border-radius: 0;
    cursor: pointer;
    text-align: center;
  }
  .three-step-button:hover {
    background-color: #ff9d33;
}

  /* Desktop style */
  @media (min-width: 768px) {
    .three-step-button {
      width: auto;
      border-radius: 0; /* keep corners square */
      float: right;
    }
  }
  
  /* Mobile style */
  @media (max-width: 767px) {
    .three-step-header h2 {
    font-size: 2.25rem;
    }
    .three-step-button {
      width: 80%;
      margin: 0 auto;
      display: block;
      border-radius: 0;
      padding: 1.2rem;
      font-size: 1rem;
      margin-top: 2rem;
    }
  }
  
  .three-step-grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .three-step-card {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    text-align: left;
  }
  
  /* Number and Arrow Row */
  .three-step-number-row {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .three-step-number {
    font-size: 2.5rem;
    font-weight: 600;
    color: black;
    margin-right: 1rem;
  }
  
  .three-step-line {
    flex-grow: 1;
    height: 1.5rem;
    position: relative;
  }
  
  .three-step-arrow {
    width: 100%;
    height: 24px;
    display: block;
    
  }
 
  
  
  /* Title & Description */
  .three-step-title {
    font-size: 1.3rem;
    font-weight: 500;
    margin: 1rem 0 0.5rem;
  }
  
  .three-step-description {
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
  }
  