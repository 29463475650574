.btn-header-primary {
  background-color: #1a76c2;
  color: white;
  padding: 10px 30px;
  border: none;
  border-radius: 0px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.2s ease;
}
.btn-header-primary-mobile {
  background-color: #1a76c2;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 0px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.2s ease;
}
.header-marleft-10{
  margin-left: 10px;
}
.btn-header-primary:hover {
  background-color: #ff9d33;
  color: white;
}

.btn-header-secondary {
  background: none;
  color: #1a76c2;
  padding: 10px 30px;
  border: none;
  border-radius: 0px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition: color 0.2s ease;
}

.btn-header-secondary:hover {
  color: #ff9d33;
}


.desktop-header {
  position: relative;
}
.header-phone-row {
  display: flex;
  gap: 20px;
  flex-direction: row;
  justify-content: center;
  height: 30px;

  color: black;
}
.nav-header-menu-item-mobile-w-children{
  display: flex;
  justify-content: space-between;
cursor:pointer;
   }
.nav-header-menu-item-mobile {
  text-decoration: none;
  margin-bottom: 2;
  color: white;
  font-size: 16px;
  width: 100%;

  text-decoration: none;

  padding: 8px 12px;
  margin-bottom: 4px;
  border-bottom:1px solid white;
  display: block;
}
.nav-header-menu-item-mobile:hover {
  color: #ff9d33;
  border-bottom:1px solid #ff9d33;
  

  text-decoration: none;
}
.nav-hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: relative;
}


.nav-hamburger span {
  background: currentColor;
  height: 4px;
  margin-top: 2px;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

/* Open state: Transform hamburger into X */
.nav-hamburger.open span:nth-child(1) {
  transform: translateY(10px) rotate(45deg);
}
.nav-hamburger.open span:nth-child(2) {
  opacity: 0;
}
.nav-hamburger.open span:nth-child(3) {
  transform: translateY(-10px) rotate(-45deg);
}

.nav-hamburger {
  font-size: 30px;
}
.mobile-nav-button {
  text-decoration: none !important;
  width: 80%;    
  
  height: 50px; /* Fixed height */
  display: flex;     
  justify-content: center; 
  align-items: center;
  background-color: #ff9d33; 
  color: white;    
  font-size: 16px;    
  font-weight: bold;  
  margin: 0 auto;
  border: none;       
  border-radius: 6px; 
  cursor: pointer;    
  margin-top: 20px;   

  /* Prevent parent from affecting height */
  flex-shrink: 0; /* Prevents shrinking */
  flex-grow: 0; /* Prevents it from stretching */
  overflow: hidden; /* Ensures content doesn’t expand it */
}

/* Hover Effect */
.mobile-nav-button:hover {
  background-color: rgb(20, 100, 170); /* Darker shade on hover */
}


.nav-header-menu-mobile {
  max-height: 80vh; /* Adjust height limit */
  overflow-y: auto; /* Enables vertical scrolling */
  scrollbar-width: thin; /* Makes scrollbar less intrusive */
  scrollbar-color: rgb(26, 118, 194) rgba(0, 0, 0, 0.1); /* Custom scrollbar color */
  background-color: rgb(26, 118, 194);
  padding: 20px 0;
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  padding-bottom: 40px;

  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.nav-kontakt-mobile {
  font-size: 15px;
  margin-right: 15px;
  background-color: rgb(26, 118, 194);
  color: white;
  text-decoration: none;
  padding: 6px 12px;
  border-radius: 16px;
}
.nav-kontakt-mobile:hover {
  background-color: #ff9d33;
  transition: 0.1s;
}
.header-phone-row p {
  margin-top: 5px;
  margin-bottom: -10px;
  color: black;
  font-weight: 600;

  font-size: 12px;
  text-decoration: none;
  text-align: left;
}
.header-phone-row a {
  margin-top: 10px;
  margin-bottom: -10px;
  color: black;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  text-align: left;
}
.header-phone-row-span {
  text-decoration: none;
}
.header-logo-white{
  margin-top: 2.5px;

  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(217deg) brightness(106%) contrast(103%);
}
.header-logo {
  margin-top: 2.5px;
  filter: invert(33%) sepia(51%) saturate(1880%) hue-rotate(184deg)
    brightness(98%) contrast(85%);
}
.header-logo- {
  margin-top: 2.5px;
  filter: invert(33%) sepia(51%) saturate(1880%) hue-rotate(184deg)
    brightness(98%) contrast(85%);
}
.nav-hamburger {
  margin: 0;
  margin-top: -6px;
}
/* Base styling for the bottom row */
.header-bottom-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent; /* default background */
  /* border-bottom: 1px solid #ccc;  If you want a bottom border */
}

/* If scrolled, we add this class dynamically from the component */
.header-bottom-row.header-scrolled {
  background-color: #1a76c2;
}

/* Container that watches mouse enter/leave */
.header-nav-container {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  height: 40px;
  background-color: transparent;
  max-width: 1440px;
  width: 100%; /* so child can stretch to fill it */

  align-self: center;
  gap: 0px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.header-nav-container.expanded {
  height: 550px;
}

.header-dropdown-container {
  align-items: flex-start; /* Aligns text to the left */
  height: inherit;

  display: flex;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  gap: 40px; /* Space between left and right columns */
  text-align: left;
  flex-direction: row;
  align-items: flex-start; /* Ensure left-to-left alignment */
}

/* Left column: list of links */
.header-dropdown-left-container {
  border-right: 1px solid #1a76c2; /* Add border */
  padding-right: 23px;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start; /* Aligns text to the left */
  width: 250px; /* Set a fixed width so right column doesn't shift */
}

/* Right column: hover text */
.header-dropdown-right-container {
  display: flex;

  flex-direction: column;
  align-items: flex-start; /* Aligns text to the left */
  width: auto; /* Adjusts dynamically to content */
  max-width: 600px; /* Prevents it from expanding too much */
}

.dropdown-link {
  text-decoration: none;
  padding: 8px 12px;
  color: black;
  width: 100%;
  text-align: left; /* Ensure text stays left-aligned */
}
.nav-header-menu-item-mobile-child{
  font-size: 16;
  text-decoration: none;
  color: rgb(215, 215, 215);
  width: 100%;
  cursor: pointer;
  padding: 8px 12px;
  margin-bottom: 4px;
  border-bottom:1px solid rgb(215, 215, 215);
  display: block;
}
.nav-header-menu-item-mobile-child:hover{
  color: #ff9d33;
  border-bottom:1px solid #ff9d33;
  text-decoration: none;


}

.dropdown-link:hover {
  background-color: #1a76c2;
  color: white;
}

/* The inner row that actually holds the content */
.header-inner-row {
  display: flex;
  position: absolute; /* so the dropdown can be placed above it */
  top: 0;
  flex-direction: row;
  height: 40px;
}

.header-dropdown {
  display: none;
}
.header-dropdown.shown {
  margin-top: 40px;
  display: flex;
  align-items: flex-start;
  width: 100vw; /* full viewport width */
  background-color: rgb(255, 250, 245);
  height: 509px;
}

.header-accordion-header {
  cursor: pointer;
  font-weight: bold;
}

.header-accordion-content {
  padding-left: 15px;
}

.header-child-item {
  display: block;
  padding: 5px 0;
}

@media only screen and (max-width: 560px) {
  /* CSS rules for screens smaller than 560px */
  .header-phone-row {
    height: 40px;
    flex-direction: column;
    margin-top: 0px;
    gap: 6px;
  }
  .header-phone-row a,
  .header-phone-row p {
    margin: 0;
    padding: 0;
    text-decoration: none;
    font-size: 12px;
    gap: 0;
    text-align: left;
    margin-left: 10px;
  }
  .nav-hamburger {
    margin: 0;
  }
}
